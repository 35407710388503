import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

function CustomTextField({value, onChange}) {
  
  
  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Enter suburb or Postal code..."
      onChange={onChange}
      value = {value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        style: {
          borderRadius: '8px',
          height: '40px',
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderRadius: `8px`,
          },
          "&:hover fieldset": {
            borderColor: "gray",
          },
          "&.Mui-focused fieldset": {
            borderColor: "gray",
          },
          '& .MuiInputBase-input::placeholder': { // Target the placeholder styles
            color: '#919191', // Set your desired placeholder color
            fontSize: '12px',
            fontWeight: '400',
            
          },
        }
      }}
    />
  );
}

export default CustomTextField;
