import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import Aus from "../Images/Australia.png";
import Ind from "../Images/India.png";
import Sg from "../Images/Singapore.png";
import Nz from "../Images/NewZealand.png";
import Sl from "../Images/SriLanka.png";
import Pil from "../Images/Philippines.png";
import Indo from "../Images/Indonesia.png";
import Thai from "../Images/Thailand.png";
import Viet from "../Images/Vietnam.png";
import Mala from "../Images/Malaysia.png";

import Map from "./Maps";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import "./Form.css";
import CustomTextField from "./CustomTextfield";
import RadiusSlider from "./RadiusSlider";
import NearestSuburbsList from "./CustomList";
import Lottie from "react-lottie";
import animationData from './anim_loading.json';

import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { db } from "../config/firestore";


function Form() {
  /* const suggestList = { name: "", postalCode: "" }; */
  const [suggestList, setSuggestList] = useState([]); // [ {name: "", postalCode: ""}, {name: "", postalCode: ""}, ...
  const [suggestLength, setSuggestLength] = useState(1);
  //fetched data saved in here
  const [finalList, setFinalList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [center, setCenter] = useState([]);

  const [myList02, setMyList02] = useState({
    name: "",
    postalCode: "",
    distance: "",
  });

  const [radius, setRadius] = useState(15); //send for search
  const [typedName, setTypedName] = useState(""); //send for suggest
  const [typedPostalCode, setTypedPostalCode] = useState(""); //send for suggest

  const [CustomText, setCustomText] = useState(""); //send for customTextfield
  const [CustomTextPostal, setCustomTextPostal] = useState(""); //send for customTextfield

  const [name, setName] = useState(""); //send for search
  const [postalCode, setPostalCode] = useState(""); //send for search
  const [country, setCountry] = useState("1");
  const [hide, setHide] = useState(false);
  const [hideBottomView, setHideBottom] = useState(true);
  const [suggestView, setSuggestView] = useState(false);
  const [suburbs, setSuburbs] = useState([]);


  const handleInputChange = (item) => {
    setName(item.areaName);   // areaName 
    setCustomText(item.areaName);   // areaName 
    setPostalCode(item.postcode);
    setCustomTextPostal(item.postcode);
    setMyList02(item);
    setSuggestView(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  console.log("typedName", typedName);
  console.log("typedPostalCode", typedPostalCode);
  console.log('name', name);
  console.log('postalcode', postalCode);


  useEffect(() => {
    let suggestName = async () => {

      const formattedTypedName = typedName.toLowerCase();

      if (formattedTypedName === "" || formattedTypedName.length <= 2) {
        console.log("empty or too short");
        return;
      }

      const q =
        query(
          collection(db, country),
          where("suggest", ">=", formattedTypedName),
          where("suggest", "<", formattedTypedName + "\uf8ff"),
          limit(10)
        );
        
      const querySnapshot = await getDocs(q);
      const suggestionsName = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log("typedname " + typedName);
        console.log(doc.id, " => ", doc.data());
        suggestionsName.push(doc.data());

      });
      console.log("if response saved");
      console.log(suggestionsName); 
      console.log(typeof suggestionsName);
      setSuggestList(suggestionsName);
      console.log("check saved");
      setSuggestLength((suggestionsName.length)*44);
      console.log(suggestList.length);
    };
    suggestName();
  }, [typedName]);

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
  useEffect(() => {
    let suggestCode = async () => {

      const formattedTypedCode = typedPostalCode.toLowerCase();

      if (formattedTypedCode === "" || country === "9" ) {
        console.log("empty");
        return;
      }

      const q =
        query(
          collection(db, country),
          where("postcode", ">=", formattedTypedCode),
          where("postcode", "<", formattedTypedCode + "\uf8ff"),
          limit(10)
        );
        
      const querySnapshot = await getDocs(q);
      const suggestionsCode = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log("typedcode " + typedName);
        console.log(doc.id, " => ", doc.data());
        suggestionsCode.push(doc.data());

      });
      console.log("if response saved");
      console.log(suggestionsCode);
      console.log(typeof suggestionsCode);
      setSuggestList(suggestionsCode);
      console.log("check saved");
      setSuggestLength((suggestionsCode.length)*44);
      
    };
    suggestCode();
  }, [typedPostalCode]);



  useEffect(() => {
    console.log("suggestList updated:", suggestList);
  }, [suggestList]);
  
  



  const fetchData = async () => {
    setIsLoading(true);
    try {
      const postal = postalCode ? postalCode : typedPostalCode;
      const nam = name ? name : typedName;


      const response = await axios.post(
        "https://api.nearit.ai/area_within_distance",
        {
          postal_code: postal,
          area_name: nam,
          radius: parseInt(radius),
          country_code: parseInt(country),
        }
      );
      console.log("fetchData working");
      console.log(response);

      const neighbors = response.data.output.neighbors;
      const selected_area = response.data.output.selected_area;

      console.log(neighbors);
      console.log(selected_area);
      console.log("check array");

      setFinalList(neighbors);
      setCenter(selected_area);
      setSuburbs(neighbors);
      

      hideBottomView ? toggleHideView() : console.log("check hide");

      console.log("check savesd");
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => { //delete
    console.log("finalList updated:", finalList);
    console.log("center updated:", center);
    console.log(suggestList);

  }, [finalList]);

  const handleChange = (event) => {
    setCountry(event.target.value);
  };
  const toggleHide = () => {
    setHide(!hide);
  };
  const toggleHideView = () => {
    setHideBottom(!hideBottomView);
  };
  function valuetext(event, value) {
    setRadius(value);
    return `${value}`;
  }

  return (
    <div>
      
        <Map location01={finalList} centerLocation={center} country={country} 
        //onClick={setSuggestView(false)}
        />
      
      <div
        className="container"
        style={{
          position: "absolute",
          zIndex: "2",
          top: "45px",
          margin: "30px",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            color: "black",
            display: hide ? "block" : "none",
          }}
        >
          <MenuIcon onClick={toggleHide} />
        </div>
        <div
          className="form-container"
          style={{
            display: hide ? "none" : "block",
            padding: "20px",
            alignItems: "center",
            justifyContent: "center",
            width: "400px",
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            <label
              style={{
                marginBottom: "6px",
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: "700",
                color: "#2D2D2D",
              }}
            >
              Country
            </label>
            <Select
              className="select"
              value={country}
              onChange={handleChange}
              style={{
                borderRadius: "8px",
              }}
              sx={{ width: "100%" }}
            >
              <MenuItem
                style={{ display: "flex", alignItems: "center" }}
                value={"1"}
              >
                <img
                  src={Aus}
                  alt="Aus"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b> Australia</b>
              </MenuItem>
              <MenuItem value={"2"}>
                <img
                  src={Nz}
                  alt="Nz"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>New Zealand</b>
              </MenuItem>
              <MenuItem value={"3"}>
                <img
                  src={Sg}
                  alt="Sg"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Singapore</b>
              </MenuItem>
              <MenuItem value={"4"}>
                <img
                  src={Ind}
                  alt="Ind"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>India</b>
              </MenuItem>
              <MenuItem value={"5"}>
                <img
                  src={Sl}
                  alt="Sl"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Sri Lanka</b>
              </MenuItem>
              <MenuItem value={"6"}>
                <img
                  src={Pil}
                  alt="Sl"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Philippines</b>
              </MenuItem>
              <MenuItem value={"7"}>
                <img
                  src={Indo}
                  alt="Sl"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Indonesia</b>
              </MenuItem>
              <MenuItem value={"8"}>
                <img
                  src={Thai}
                  alt="Sl"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Thailand</b>
              </MenuItem>
              <MenuItem value={"9"}>
                <img
                  src={Viet}
                  alt="Sl"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Vietnam</b>
              </MenuItem>
              <MenuItem value={"10"}>
                <img
                  src={Mala}
                  alt="Sl"
                  style={{
                    width: "35px",
                    height: "22px",
                    marginRight: "10px",
                    borderRadius: "2px",
                  }}
                />
                <b>Malaysia</b>
              </MenuItem>
            </Select>
            <label
              style={{
                marginTop: "30px",
                marginBottom: "6px",
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: "700",
                color: "#2D2D2D",
              }}
            >
              Suburb name or Postal code
            </label>
            <CustomTextField value={CustomText ? CustomText : CustomTextPostal}
              className="text-field"
              onChange={(e) => {
                setTypedName("");
                setTypedPostalCode("");
                setSuggestView(false);
                if (!isNaN(e.target.value)) {
                  setTypedPostalCode(e.target.value);
                  setCustomTextPostal(e.target.value);
                  setSuggestView(true);

                } else {
                  setTypedName(e.target.value);
                  setCustomText(e.target.value);
                  setSuggestView(true);
                }

                if (e.target.value === "") {
                  setTypedName("");
                  setTypedPostalCode("");
                  setCustomText("");
                  setCustomTextPostal("");
                  setSuggestView(false);
                  setSuggestList([]);
                  setSuggestLength(0);
                }


              }}
              margin="normal"
              placeholder="Enter suburb or Postal code..."
              InputProps={{
                startAdornment: <SearchIcon style={{ color: "#888" }} />,
              }}
            />
            <RadiusSlider value={radius} onChange={valuetext} />
            <Button
              sx={{ textTransform: "none" }}
              style={{
                marginTop: "20px",
                backgroundColor: "black",
                allCaps: "false",
                borderRadius: "8px",
                color: "white",
                type: "submit",
                fontSize: "14px",
                fontWeight: "700",
                height: "40px",
                hover: { backgroundColor: "#2D2D2D" },
              }}
              onClick={() => {
                fetchData();
              }}
            >
              Search
            </Button>
            <NearestSuburbsList
              suburbs={suburbs}
              style={{
                display: !hideBottomView ? "block" : "none",
              }}
            />
          </form>
          <p
            style={{
              display: hideBottomView ? "block" : "none",
              color: "#828282",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "80px",
            }}
          >
            -Enter valid suburb or Postal code & search<br></br>
            to see results-
          </p>
          <div className="suggestList">
            <div
              style={{
                display: suggestView ? "block" : "none",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: "3",
                  top: "200px",
                  left: "20px",
                  backgroundColor: "white",
                  width: "399px",
                  height: suggestLength,
                  border: "1px solid #D6E3F6",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <table>
                  <tbody>
                    {suggestList.map((item, index) => (   //in here change the myList01 to suggestList
                      <tr key={index}>
                        <td>
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                              handleInputChange(item);
                            }}
                            style={{
                              backgroundColor: "white",
                              borderRadius: "10px",
                              color: "black",
                              border: "none",
                              textAlign: "left",
                              width: "352px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              height: "40px",
                              fontSize: "14px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              //set a hover effect
                            }}
                          >
                            <p>{item.areaName}</p>
                            {country === '6' || country === '7' ? <p>{item.postcode}</p> : <p>{item.l2Area}</p>}
                            
                            
                            {/* <p>{item.postcode}</p> */}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Lottie options={defaultOptions} height={250} width={250} />
        </div>
      )}
    </div>
  );
}

export default Form;



