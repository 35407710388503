import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

function RadiusSlider({ value, onChange }) {

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 50,
      label: "50",
    },
  ];

  return (
    <Box sx={{ width: "auto", padding: "0" }}>
      <Typography
        sx={{ marginTop: "30px" }}
        id="input-slider"
        style={{
          marginTop: "20px",
          marginBottom: "6px",
          marginLeft: "5px",
          fontSize: "12px",
          fontWeight: "700",
          color: "#2D2D2D",
        }}
        gutterBottom
      >
        Search Radius (KM)*
      </Typography>
      <Slider
        aria-labelledby="input-slider"
        value={value}
        onChange={onChange}
        step={1}
        min={0}
        max={50}
        valueLabelFormat={(value) => `${value} km`}
        marks={marks}
        valueLabelDisplay="auto"
        sx={{
          color: "#55CC1E",
          "& .MuiSlider-rail": {
            opacity: 0.5,
            backgroundColor: "#bfbfbf",
          },
          "& .MuiSlider-thumb": {
            color: "#55CC1E", // Color of the thumb
          },
          "& .MuiSlider-valueLabel": {
            // Style of the value label
            backgroundColor: "#55CC1E",
          },
          "& .MuiSlider-track": {
            // Style of the track
            border: "none",
          },
        }}
      />
    </Box>
  );
}

export default RadiusSlider;
