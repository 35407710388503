import React from "react";
import { useState, useEffect } from "react";
import "./Map.css";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  Icon,
} from "react-leaflet";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const redIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-shadow.png",
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

function ChangeView({ center, zoom, suburbs }) {
  const map = useMap();

  useEffect(() => {
    if (suburbs.length) {
      const bounds = L.latLngBounds(
        suburbs.map((loc) => [loc.latitude, loc.longitude])
      );
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [suburbs, map]);

  return null; // This component does not render anything itself
}

function MapComponent(props) {
  const { location01, centerLocation, country } = props;
  const [mapcenter, setMapCenter] = useState({ latitude: "", longitude: "" });
  console.log("centerLocation", centerLocation);

  const initialCenter = centerLocation || [-25.2744, 133.7751];
  const zoomLevel = 6;

  useEffect(() => {
    switch (country) {
      case "1": //Australia
        setMapCenter({ latitude: -25.2744, longitude: 133.7751 });
        break;
      case "2": //New Zealand
        setMapCenter({ latitude: -40.9006, longitude: 174.886 });
        break;
      case "3": //Singapore
        setMapCenter({ latitude: 20.5937, longitude: 78.9629 });
        break;
      case "4": //India
        setMapCenter({ latitude: 37.0902, longitude: -95.7129 });
        break;
      case "5": //Sri Lanka
        setMapCenter({ latitude: 55.3781, longitude: -3.436 });
        break;
      default:
        setMapCenter({ latitude: -25.2744, longitude: 133.7751 });
        break;
    }
  }, [country]);

  return (
    <div>
      <MapContainer
        center={[-25.2744, 133.7751]}
        zoom={zoomLevel}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {location01.map((location01, index) =>
          location01.longitude != centerLocation.longitude && location01.longitude != centerLocation.latitude  ? (
            <Marker
              key={index}
              position={[
                parseFloat(location01.latitude),
                parseFloat(location01.longitude),
              ]}
            >
              <Popup>
                {location01.area_name}
                <br />
                Distance: {location01.distance.toFixed(2)} km
              </Popup>
            </Marker>
          ) : (
            <Marker
              icon={redIcon}
              position={[
                parseFloat(centerLocation.latitude),
                parseFloat(centerLocation.longitude),
              ]}
            >
              <Popup>
                {centerLocation.area}
              </Popup>
            </Marker>
          )
        )}
        <ChangeView
          center={initialCenter}
          zoom={zoomLevel}
          suburbs={location01}
        />
      </MapContainer>
    </div>
  );
}

export default MapComponent;
