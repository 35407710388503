import React from 'react'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Img01 from '../Images/Illustrations01.png';
import Img02 from '../Images/Illustrations02.png';
import Img03 from '../Images/Illustrations03.png';

function Popups(props) {
    const { open, onClose } = props;
    
    
    const [page, setPage] = useState(1);
    const nextPage = () => {
        setPage(page + 1);
    }
    const prevPage = () => {
        setPage(page - 1);
    }
    const closePopup = () => {
        onClose();
        setPage(1);
    }
    return (
        <div>
            <div className="popup"
                style={{
                    position: 'absolute',  //change 
                    zIndex: '3',  //change 
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '1px 20px 20px 20px',
                    display: open ? "block" : "none",
                    width: '745px',
                    height: '450px',

                }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '14px',
                        
                    }}>
                    <p>How NearIt Work</p>
                    <CloseIcon onClick={closePopup}
                    sx={{color: '#A8A8A8',}}/>
                </div>
                <div
                style={{
                    color: '#3AB002',
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '20px',
                    margin: '20px',
                }}>
                    <p style={{display: page === 1 ? 'block' : 'none'}}>Select Country & Enter Suburb Name or Postal Code</p>
                    <p style={{display: page === 2 ? 'block' : 'none'}}>Set Preferred Radius</p>
                    <p style={{display: page === 3 ? 'block' : 'none'}}>All done! Get the list of nearby suburbs </p>
                    <img src={Img01} alt='img01' style={{ display: page === 1 ? 'block' : 'none', marginLeft: 'auto', marginRight: 'auto' }} />
                    <img src={Img02} alt='img02' style={{ display: page === 2 ? 'block' : 'none', marginLeft: 'auto', marginRight: 'auto' }} />
                    <img src={Img03} alt='img03' style={{ display: page === 3 ? 'block' : 'none', marginLeft: 'auto', marginRight: 'auto' }} />
                    <p
                        style={{
                            textAlign: 'center',
                            color: '#595959',
                            margin: '3px',
                            fontSize: '14px',
                            lineHeight: '20px',
                            display: page === 1 ? 'block' : 'none',                        }}>Please select a country and choose a suburb name or postal code from the searchable dropdown list,
                        <br></br>We will use the selected suburb as the center location.</p>
                        <p
                        style={{
                            textAlign: 'center',
                            color: '#595959',
                            margin: '3px',
                            fontSize: '14px',
                            lineHeight: '20px',
                            display: page === 2 ? 'block' : 'none',                        }}>You can specify your preferred search area as a radius in kilometers
                        <br></br>and then click on "search".</p>
                        <p
                        style={{
                            textAlign: 'center',
                            color: '#595959',
                            margin: '3px',
                            fontSize: '14px',
                            lineHeight: '20px',
                            display: page === 3 ? 'block' : 'none',
                        }}>Great! Now that everything is set, you can view the full list of nearby suburbs 
                        <br></br>to your selected location.</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                    }}>
                    <div><PlayCircleFilledWhiteOutlinedIcon
                        sx={{
                            color: '#3AB002',
                            rotate: '180deg',
                            fontSize: '30px',
                            display: page === 1 ? 'none' : 'block',
                        }}
                        onClick={prevPage} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '17px',
                            marginLeft: page === 1 ? '29.5px' : '0px',
                            marginRight: page === 3 ? '29.5px' : '0px',
                        }}>
                        <FiberManualRecordIcon
                            sx={{
                                color:page === 1 ? '#3AB002':'#D9D9D9',
                                fontSize: '15px',
                            }} />
                        <FiberManualRecordIcon
                            sx={{
                                color: page === 2 ? '#3AB002':'#D9D9D9',
                                fontSize: '15px',
                            }} />
                        <FiberManualRecordIcon
                            sx={{
                                color:page === 3 ? '#3AB002':'#D9D9D9',
                                fontSize: '15px',
                            }} />
                    </div>
                    <div><PlayCircleFilledWhiteOutlinedIcon
                        sx={{
                            color: '#3AB002',
                            fontSize: '30px',
                            display: page === 3 ? 'none' : 'block',
                        }}
                        onClick={nextPage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popups
