import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import PropTypes from "prop-types";

function NearestSuburbsList({ suburbs, style }) {
  const handleCopyAll = () => {
    const allSuburbsText = suburbs
      .map(
        (suburb) =>
          `${suburb.area_name} ${suburb.postcode} ${suburb.distance} KM`
      )
      .join("\n");
    navigator.clipboard.writeText(allSuburbsText);
  };

  return (
    <div
      style={{
        ...style,
        marginTop: "20px",
        padding: "5px",
        color: "#2D2D2D",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            color: "#3AB002",
            fontWeight: "700",
          }}
        >
          <div style={{ display: "flex", marginRight: "5px" }}>
            <b>Nearest Suburbs</b>
            <span> ({suburbs.length} Found)</span>
          </div>
          <IconButton
            onClick={handleCopyAll}
            style={{
              backgroundColor: "#3AB002",
              color: "white",
              fontSize: "10px",
              borderRadius: "20px",
              border: "none",
              height: "30px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
              left: "100px",
            }}
          >
            <ContentCopyRoundedIcon />
            <span>Copy all</span>
          </IconButton>
        </div>
      </Box>
      <Paper
        sx={{
          position: "relative",
          width: "calc(100% - 10px)", // Correct the width calculation
          maxHeight: "calc(53vh - 150px)", // Set the max height to be the full height minus the height of other
          overflowY: "auto",
          marginTop: 2,
          padding: 2,
          boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <List dense>
          <table>
            <tbody>
              {suburbs.map((suburb, index) => (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: "#F3F3F3",
                      borderRadius: "10px",
                      color: "black",
                      border: "none",
                      textAlign: "left",
                      width: "310px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      marginBottom: "10px",
                      height: "40px",
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ width: "40%" }}>{suburb.area_name}</p>
                    <p style={{ width: "20px" }}>{suburb.postcode}</p>
                    <p style={{ width: "60px", textAlign: "right" }}>
                      {suburb.distance} KM
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </List>
      </Paper>
    </div>
  );
}

// Set the PropTypes for the component
NearestSuburbsList.propTypes = {
  suburbs: PropTypes.array.isRequired,
  style: PropTypes.object,
};

// Ensure you have a default prop for style in case it is not passed
NearestSuburbsList.defaultProps = {
  style: {},
};

export default NearestSuburbsList;
