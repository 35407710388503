
import React from 'react';

import Navbar from './components/Navbar.js';
import Form from './components/Form.js';


function App() {
  return (
    <div className="App">
      <Form/>
      <Navbar/>
    </div>
  );
}

export default App;
